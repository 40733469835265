/* eslint-disable max-len, camelcase */
import {
  addDays,
  addMonths,
} from 'date-fns';

const now = new Date();
const startDate = addMonths(now, -1);
const creationDate = addDays(startDate, -1);
const endDate = addDays(startDate, 8);
const day1 = addDays(startDate, 0);
const day2 = addDays(startDate, 1);
const day3 = addDays(startDate, 2);
const day4 = addDays(startDate, 3);
const day5 = addDays(startDate, 4);
const day6 = addDays(startDate, 5);
const day7 = addDays(startDate, 6);
const day8 = addDays(startDate, 7);
// const day9 = addDays(startDate, 8);

const SampleCampaign = {
  id: '-1',
  createdAt: creationDate,
  status: 'COMPLETED',
  ugc: {
    fields: {
      artistName: 'Soni Shae',
      releaseGenre: 'Electronic',
      releaseName: 'Missed Calls',
      releaseType: 'Single',
    },
    images: [
      {
        id: '352439',
        name: 'Soni_2.jpeg',
        type: 'image/jpeg',
        url: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/6f537b8c-e093-4d9a-98fe-b3987bd9e78b?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        width: 2121,
        height: 1414,
        color_palette: {
          averageLuminance: 0.341875,
          color1: 'fc93ab',
          color2: 'b2cae5',
          color3: 'a92b57',
          color4: '91284b',
          color5: '712845',
          color6: '4b2518',
          vibrant: 'cb3969',
          vibrantDark: '562529',
          vibrantLight: 'fc93ab',
          muted: 'a65c6b',
          mutedDark: '562529',
          mutedLight: '9cb3d3',
        },
      },
      {
        id: '292850',
        name: 'GettyImages-1182489219.jpeg',
        type: 'image/jpeg',
        url: 'https://bandlab-zire.imgix.net/user/mzGOl2TpMhXw7x0eWh02917ta872/48dc93bc-63db-4f65-a1cd-7c0449a3167f?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        width: 1414,
        height: 2121,
        color_palette: {
          averageLuminance: 0.208286,
          color1: 'edcbb4',
          color2: 'cc2879',
          color3: 'a93390',
          color4: '6f172f',
          color5: '563627',
          color6: '3c2b22',
          vibrant: 'da9c2d',
          vibrantDark: '734715',
          vibrantLight: 'e171d0',
          muted: '9d5961',
          mutedDark: '522c2e',
          mutedLight: 'ddb5b6',
        },
      },
      {
        id: '352438',
        name: 'Soni_1.jpeg',
        type: 'image/jpeg',
        url: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/81450b90-511f-4faa-85b8-c31f0de64fe9?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        width: 1414,
        height: 2120,
        color_palette: {
          averageLuminance: 0.291477,
          color1: 'fde4e1',
          color2: '1cddec',
          color3: '9577c4',
          color4: '697798',
          color5: '8a1971',
          color6: '67231d',
          vibrant: 'ee0551',
          vibrantDark: '8b020b',
          vibrantLight: '6be7f8',
          muted: 'a555a8',
          mutedDark: '532e30',
          mutedLight: 'cbbcd5',
        },
      },
    ],
    audio: [
      {
        id: '391291',
        source: {
          id: '148433',
          name: 'Missed Calls',
          duration_ms: 343414,
          url: 'https://storage.googleapis.com/zire-production.appspot.com/private/audio_source/user/mzGOl2TpMhXw7x0eWh02917ta872/streaming/7cf6b834-d33d-43ba-9ce4-66459ce322f2?Expires=1596723786&GoogleAccessId=zire-production%40appspot.gserviceaccount.com&Signature=IvRf%2FTMGX9%2BHZhLZEpD5dpvzjHSanjCRikURY0NdIK95M%2B7zH7%2FzKbVI%2B2TPB%2BzncjvZzbL20rT3T%2BkQRw5LaHOjen4j6Io%2BZnGaXFhFEibGShuoxyLe6i5pKzyeY1hKPuBpMsHJJo2BN66PvNES37nXeox5B1XdZP1hqi7IG5lAdNw%2FwdOGvC1TSxlTgZAxRlN6vshpTBPkGvO%2F2uYUBaKY9HDFHwZRuazPWFzaSPyaT%2FYRwQ0i8hcwki%2BBO%2BPS%2BLjAIjSG1pLaH6zHZZB2on8L4LbOK7tgue7BCxl5K95I9SLpDdURMtrLb%2FApp54Pz7zubIMHPLGTrWK8Lyj%2BTw%3D%3D',
          content_type: 'audio/mpeg',
        },
        start_ms: 157000,
        duration_ms: 30040,
        url: 'https://firebasestorage.googleapis.com/v0/b/zire-production.appspot.com/o/public%2Faudio%2Fuser%2Fhy6e7OrHeTTx1vjPb5iCebnyq2s1%2F148433_preview.mp3?alt=media',
        content_type: 'audio/mpeg',
      },
    ],
  },
  landingPages: [
    {
      retailer: 'apple',
      url: 'https://music.apple.com/us/album/missed-calls-single/1497938085',
      ogImageUrl: 'https://is4-ssl.mzstatic.com/image/thumb/Music113/v4/6c/aa/91/6caa91cb-6311-0de8-b167-c8fba460a09b/767501364276.png/600x600bf.png',
    },
    {
      retailer: 'deezer',
      url: 'https://www.deezer.com/album/126039002',
      ogImageUrl: 'https://e-cdns-images.dzcdn.net/images/cover/9886fe3c508d47ed2a875826f8e75f96/500x500.jpg',
    },
    {
      retailer: 'spotify',
      url: 'https://open.spotify.com/album/6eQGMmHMqhBp4F15m6BwRD',
      ogImageUrl: 'https://i.scdn.co/image/ab67616d0000b273ac2de223efc082d07dffe9ff',
    },
  ],
  budget: {
    currency: 'usd',
    amount: 10000,
  },
  durationDays: 7,
  submittedAt: creationDate,
  activatedAt: startDate,
  endedAt: endDate,
  adNetworks: [
    'display',
    'facebook',
    'instagram',
  ],
  facebookPage: {
    pageId: '105307191162779',
    name: 'Soni Shae',
    link: 'https://www.facebook.com/105307191162779',
    pictureUrl: 'https://bandlab-zire.imgix.net/user/hy6e7OrHeTTx1vjPb5iCebnyq2s1/2e26ddb2-c15a-4975-9e7c-7df434800a09?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
    instagram: {
      id: '2455051461263442',
      username: 'shaesoni',
      pictureUrl: 'https://bandlab-zire.imgix.net/user/hy6e7OrHeTTx1vjPb5iCebnyq2s1/84de36eb-3d06-44be-bed2-326d6daacd49?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
    },
  },
};

const SampleReports = {
  campaign: {
    impressions: 25680,
    interactions: 578,
    clicks: 16,
    retailers: [
      {
        retailer: 'apple',
        clicks: 45,
      },
      {
        retailer: 'deezer',
        clicks: 39,
      },
      {
        retailer: 'spotify',
        clicks: 367,
      },
    ],
    audio: [
      {
        id: 391291,
        durationMs: 30040,
        plays: 43,
        progress: [
          {
            durationMs: 0,
            percentage: 0,
            count: 7,
          },
          {
            durationMs: 1202,
            percentage: 4,
            count: 1,
          },
          {
            durationMs: 1802,
            percentage: 6,
            count: 1,
          },
          {
            durationMs: 2103,
            percentage: 7,
            count: 1,
          },
          {
            durationMs: 3905,
            percentage: 13,
            count: 5,
          },
          {
            durationMs: 4206,
            percentage: 14,
            count: 1,
          },
          {
            durationMs: 14419,
            percentage: 48,
            count: 1,
          },
          {
            durationMs: 18324,
            percentage: 61,
            count: 2,
          },
          {
            durationMs: 18925,
            percentage: 63,
            count: 1,
          },
          {
            durationMs: 19226,
            percentage: 64,
            count: 1,
          },
          {
            durationMs: 21028,
            percentage: 70,
            count: 6,
          },
          {
            durationMs: 24032,
            percentage: 80,
            count: 2,
          },
          {
            durationMs: 25234,
            percentage: 84,
            count: 1,
          },
          {
            durationMs: 27036,
            percentage: 90,
            count: 1,
          },
          {
            durationMs: 30040,
            percentage: 100,
            count: 12,
          },
        ],
        feedback: [
          {
            rating: 1,
            percentage: 4.545454545454546,
          },
          {
            rating: 2,
            percentage: 13.636363636363637,
          },
          {
            rating: 3,
            percentage: 27.272727272727273,
          },
          {
            rating: 4,
            percentage: 36.36363636363637,
          },
          {
            rating: 5,
            percentage: 18.181818181818183,
          },
        ],
        averageFeedback: 3.5000000000000004,
      },
    ],
    socialActivity: {
      reactions: 44,
      shares: 2,
      saves: 6,
      pageLikes: 0,
    },
    promoPage: {
      url: '',
      impressions: 0,
    },
  },
  daily: [
    {
      date: day1,
      report: {
        impressions: 3242,
        interactions: 115,
      },
    },
    {
      date: day2,
      report: {
        impressions: 3411,
        interactions: 89,
      },
    },
    {
      date: day3,
      report: {
        impressions: 3456,
        interactions: 51,
      },
    },
    {
      date: day4,
      report: {
        impressions: 4000,
        interactions: 91,
      },
    },
    {
      date: day5,
      report: {
        impressions: 3950,
        interactions: 85,
      },
    },
    {
      date: day6,
      report: {
        impressions: 4023,
        interactions: 80,
      },
    },
    {
      date: day7,
      report: {
        impressions: 3319,
        interactions: 62,
      },
    },
    {
      date: day8,
      report: {
        impressions: 1997,
        interactions: 53,
      },
    },
  ],
  domain: [
    {
      domain: '-',
      retargeted: true,
      report: {
        impressions: 20,
        interactions: 40,
      },
    },
    {
      domain: '1001tracklists.com',
      report: {
        impressions: 52,
        interactions: 0,
      },
    },
    {
      domain: '8tracks.com',
      report: {
        impressions: 26,
        interactions: 0,
      },
    },
    {
      domain: '9to5mac.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'albumoftheyear.org',
      report: {
        impressions: 217,
        interactions: 3,
      },
    },
    {
      domain: 'allfreeknitting.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'allkpop.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'allmusic.com',
      report: {
        impressions: 350,
        interactions: 5,
      },
    },
    {
      domain: 'alot.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'animalcrossingworld.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'answers.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'azlyrics.com',
      report: {
        impressions: 4,
        interactions: 0,
      },
    },
    {
      domain: 'bandsintown.com',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'bigthink.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'billboard.com',
      report: {
        impressions: 1367,
        interactions: 11,
      },
    },
    {
      domain: 'brit.co',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'brooklynvegan.com',
      report: {
        impressions: 25,
        interactions: 0,
      },
    },
    {
      domain: 'businessinsider.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'chilipeppermadness.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'cnn.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 1,
      },
    },
    {
      domain: 'complex.com',
      report: {
        impressions: 543,
        interactions: 6,
      },
    },
    {
      domain: 'consequenceofsound.net',
      report: {
        impressions: 296,
        interactions: 6,
      },
    },
    {
      domain: 'constative.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'ctvnews.ca',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'dailymotion.com',
      retargeted: true,
      report: {
        impressions: 4,
        interactions: 0,
      },
    },
    {
      domain: 'definition.org',
      retargeted: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      domain: 'discogs.com',
      report: {
        impressions: 915,
        interactions: 8,
      },
    },
    {
      domain: 'divascancook.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'edm.com',
      report: {
        impressions: 45,
        interactions: 0,
      },
    },
    {
      domain: 'edmsauce.com',
      report: {
        impressions: 24,
        interactions: 0,
      },
    },
    {
      domain: 'elquintobeatle.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'ew.com',
      report: {
        impressions: 796,
        interactions: 3,
      },
    },
    {
      domain: 'fandom.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'fark.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'fleaflicker.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'foxnews.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'gamesradar.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'gazzetta.it',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'genius.com',
      report: {
        impressions: 967,
        interactions: 9,
      },
    },
    {
      domain: 'gizmodo.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'gladly.io',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'healthline.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'healthygeorge.com',
      retargeted: true,
      report: {
        impressions: 3,
        interactions: 0,
      },
    },
    {
      domain: 'heavy.com',
      retargeted: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      domain: 'hollywoodlife.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'hypem.com',
      report: {
        impressions: 6,
        interactions: 3,
      },
    },
    {
      domain: 'iheart.com',
      report: {
        impressions: 1,
        interactions: 2,
      },
    },
    {
      domain: 'independent.co.uk',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'indiatimes.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'indieshuffle.com',
      report: {
        impressions: 3,
        interactions: 0,
      },
    },
    {
      domain: 'investing.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'koreaboo.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'kpopstarz.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'lanacion.com.ar',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'las2orillas.co',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'last.fm',
      report: {
        impressions: 164,
        interactions: 2,
      },
    },
    {
      domain: 'latimes.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'lawenforcementtoday.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'livingmgz.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'loudwire.com',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'lrytas.lt',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'lyricsfreak.com',
      report: {
        impressions: 42,
        interactions: 2,
      },
    },
    {
      domain: 'lyricsmode.com',
      report: {
        impressions: 58,
        interactions: 0,
      },
    },
    {
      domain: 'mentalfloss.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'metrolyrics.com',
      report: {
        impressions: 4,
        interactions: 0,
      },
    },
    {
      domain: 'misspennystocks.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'mixcloud.com',
      report: {
        impressions: 473,
        interactions: 2,
      },
    },
    {
      domain: 'moneyversed.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'mtv.com',
      report: {
        impressions: 370,
        interactions: 2,
      },
    },
    {
      domain: 'musicoholics.com',
      retargeted: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      domain: 'musicradar.com',
      report: {
        impressions: 169,
        interactions: 2,
      },
    },
    {
      domain: 'newsarama.com',
      retargeted: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      domain: 'nine.com.au',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'nme.com',
      report: {
        impressions: 33,
        interactions: 0,
      },
    },
    {
      domain: 'noticiasaominuto.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'onecountry.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'parlamentnilisty.cz',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'pastemagazine.com',
      report: {
        impressions: 1502,
        interactions: 3,
      },
    },
    {
      domain: 'phandroid.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'pinknews.co.uk',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'pitchfork.com',
      report: {
        impressions: 248,
        interactions: 2,
      },
    },
    {
      domain: 'poynter.org',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'pupperish.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'purevolume.com',
      report: {
        impressions: 3,
        interactions: 0,
      },
    },
    {
      domain: 'ranker.com',
      retargeted: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      domain: 'ratemyjob.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'relix.com',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'riggosrag.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'rightmove.co.uk',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'rocketgeeks.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'rollingstone.com',
      report: {
        impressions: 2430,
        interactions: 10,
      },
    },
    {
      domain: 'smokedbbqsource.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'spin.com',
      report: {
        impressions: 71,
        interactions: 0,
      },
    },
    {
      domain: 'sporcle.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'stereogum.com',
      report: {
        impressions: 597,
        interactions: 5,
      },
    },
    {
      domain: 'teddyfeed.com',
      retargeted: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      domain: 'theblast.com',
      retargeted: true,
      report: {
        impressions: 3,
        interactions: 0,
      },
    },
    {
      domain: 'thefader.com',
      report: {
        impressions: 35,
        interactions: 0,
      },
    },
    {
      domain: 'theguardian.com',
      retargeted: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      domain: 'thekitchn.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'thelineofbestfit.com',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'themodestman.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'theperspective.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'thesportster.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'thesun.co.uk',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'thissongissick.com',
      report: {
        impressions: 6,
        interactions: 0,
      },
    },
    {
      domain: 'tinymixtapes.com',
      report: {
        impressions: 4,
        interactions: 0,
      },
    },
    {
      domain: 'tmz.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'toofab.com',
      report: {
        impressions: 394,
        interactions: 2,
      },
    },
    {
      domain: 'uncut.co.uk',
      report: {
        impressions: 3,
        interactions: 0,
      },
    },
    {
      domain: 'unleashedfinance.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'uproxx.com',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'usmagazine.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'vanityfair.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'variety.com',
      report: {
        impressions: 497,
        interactions: 2,
      },
    },
    {
      domain: 'vh1.com',
      report: {
        impressions: 180,
        interactions: 6,
      },
    },
    {
      domain: 'vintagevinylnews.com',
      report: {
        impressions: 3,
        interactions: 0,
      },
    },
    {
      domain: 'whatculture.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'wildlifeinsider.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'wtov9.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'yahoo.com',
      retargeted: true,
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      domain: 'yourdictionary.com',
      retargeted: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      domain: 'youredm.com',
      report: {
        impressions: 256,
        interactions: 3,
      },
    },
  ],
  country: [
    {
      country: '-',
      countryCode: '-',
      report: {
        impressions: 398,
        interactions: 40,
      },
    },
    {
      country: 'Argentina',
      countryCode: 'Argentina',
      hasRegions: true,
      report: {
        impressions: 35,
        interactions: 0,
      },
    },
    {
      country: 'Australia',
      countryCode: 'Australia',
      hasRegions: true,
      report: {
        impressions: 808,
        interactions: 19,
      },
    },
    {
      country: 'Austria',
      countryCode: 'Austria',
      hasRegions: true,
      report: {
        impressions: 9,
        interactions: 0,
      },
    },
    {
      country: 'Belgium',
      countryCode: 'Belgium',
      hasRegions: true,
      report: {
        impressions: 23,
        interactions: 0,
      },
    },
    {
      country: 'Brazil',
      countryCode: 'Brazil',
      hasRegions: true,
      report: {
        impressions: 122,
        interactions: 0,
      },
    },
    {
      country: 'Canada',
      countryCode: 'Canada',
      hasRegions: true,
      report: {
        impressions: 3804,
        interactions: 83,
      },
    },
    {
      country: 'Chile',
      countryCode: 'Chile',
      hasRegions: true,
      report: {
        impressions: 14,
        interactions: 0,
      },
    },
    {
      country: 'China',
      countryCode: 'China',
      hasRegions: true,
      report: {
        impressions: 5,
        interactions: 0,
      },
    },
    {
      country: 'Colombia',
      countryCode: 'Colombia',
      hasRegions: true,
      report: {
        impressions: 14,
        interactions: 0,
      },
    },
    {
      country: 'Costa Rica',
      countryCode: 'Costa Rica',
      hasRegions: true,
      report: {
        impressions: 7,
        interactions: 0,
      },
    },
    {
      country: 'Croatia',
      countryCode: 'Croatia',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      country: 'Curaçao',
      countryCode: 'Curaçao',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      country: 'Czechia',
      countryCode: 'Czechia',
      hasRegions: true,
      report: {
        impressions: 14,
        interactions: 0,
      },
    },
    {
      country: 'Denmark',
      countryCode: 'Denmark',
      hasRegions: true,
      report: {
        impressions: 17,
        interactions: 0,
      },
    },
    {
      country: 'Dominican Republic',
      countryCode: 'Dominican Republic',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      country: 'Ecuador',
      countryCode: 'Ecuador',
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      country: 'Egypt',
      countryCode: 'Egypt',
      hasRegions: true,
      report: {
        impressions: 6,
        interactions: 0,
      },
    },
    {
      country: 'Finland',
      countryCode: 'Finland',
      hasRegions: true,
      report: {
        impressions: 5,
        interactions: 0,
      },
    },
    {
      country: 'France',
      countryCode: 'France',
      hasRegions: true,
      report: {
        impressions: 49,
        interactions: 6,
      },
    },
    {
      country: 'Germany',
      countryCode: 'Germany',
      hasRegions: true,
      report: {
        impressions: 113,
        interactions: 0,
      },
    },
    {
      country: 'Greece',
      countryCode: 'Greece',
      hasRegions: true,
      report: {
        impressions: 13,
        interactions: 0,
      },
    },
    {
      country: 'Hungary',
      countryCode: 'Hungary',
      hasRegions: true,
      report: {
        impressions: 7,
        interactions: 0,
      },
    },
    {
      country: 'Iceland',
      countryCode: 'Iceland',
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      country: 'India',
      countryCode: 'India',
      hasRegions: true,
      report: {
        impressions: 115,
        interactions: 1,
      },
    },
    {
      country: 'Ireland',
      countryCode: 'Ireland',
      hasRegions: true,
      report: {
        impressions: 1074,
        interactions: 36,
      },
    },
    {
      country: 'Israel',
      countryCode: 'Israel',
      hasRegions: true,
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      country: 'Italy',
      countryCode: 'Italy',
      hasRegions: true,
      report: {
        impressions: 48,
        interactions: 0,
      },
    },
    {
      country: 'Jamaica',
      countryCode: 'Jamaica',
      hasRegions: true,
      report: {
        impressions: 8,
        interactions: 0,
      },
    },
    {
      country: 'Japan',
      countryCode: 'Japan',
      hasRegions: true,
      report: {
        impressions: 89,
        interactions: 0,
      },
    },
    {
      country: 'Lebanon',
      countryCode: 'Lebanon',
      report: {
        impressions: 2,
        interactions: 0,
      },
    },
    {
      country: 'Mexico',
      countryCode: 'Mexico',
      hasRegions: true,
      report: {
        impressions: 80,
        interactions: 0,
      },
    },
    {
      country: 'Netherlands',
      countryCode: 'Netherlands',
      hasRegions: true,
      report: {
        impressions: 62,
        interactions: 0,
      },
    },
    {
      country: 'New Zealand',
      countryCode: 'New Zealand',
      hasRegions: true,
      report: {
        impressions: 304,
        interactions: 10,
      },
    },
    {
      country: 'Nicaragua',
      countryCode: 'Nicaragua',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      country: 'Norway',
      countryCode: 'Norway',
      hasRegions: true,
      report: {
        impressions: 20,
        interactions: 0,
      },
    },
    {
      country: 'Panama',
      countryCode: 'Panama',
      hasRegions: true,
      report: {
        impressions: 5,
        interactions: 0,
      },
    },
    {
      country: 'Paraguay',
      countryCode: 'Paraguay',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      country: 'Peru',
      countryCode: 'Peru',
      hasRegions: true,
      report: {
        impressions: 24,
        interactions: 0,
      },
    },
    {
      country: 'Philippines',
      countryCode: 'Philippines',
      hasRegions: true,
      report: {
        impressions: 76,
        interactions: 0,
      },
    },
    {
      country: 'Poland',
      countryCode: 'Poland',
      hasRegions: true,
      report: {
        impressions: 36,
        interactions: 0,
      },
    },
    {
      country: 'Portugal',
      countryCode: 'Portugal',
      hasRegions: true,
      report: {
        impressions: 15,
        interactions: 0,
      },
    },
    {
      country: 'Puerto Rico',
      countryCode: 'Puerto Rico',
      report: {
        impressions: 6,
        interactions: 0,
      },
    },
    {
      country: 'Republic of Korea',
      countryCode: 'Republic of Korea',
      hasRegions: true,
      report: {
        impressions: 23,
        interactions: 0,
      },
    },
    {
      country: 'Republic of Moldova',
      countryCode: 'Republic of Moldova',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      country: 'Romania',
      countryCode: 'Romania',
      hasRegions: true,
      report: {
        impressions: 5,
        interactions: 0,
      },
    },
    {
      country: 'Russia',
      countryCode: 'Russia',
      hasRegions: true,
      report: {
        impressions: 59,
        interactions: 0,
      },
    },
    {
      country: 'Singapore',
      countryCode: 'Singapore',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      country: 'South Africa',
      countryCode: 'South Africa',
      hasRegions: true,
      report: {
        impressions: 26,
        interactions: 0,
      },
    },
    {
      country: 'Spain',
      countryCode: 'Spain',
      hasRegions: true,
      report: {
        impressions: 59,
        interactions: 0,
      },
    },
    {
      country: 'Sweden',
      countryCode: 'Sweden',
      hasRegions: true,
      report: {
        impressions: 31,
        interactions: 3,
      },
    },
    {
      country: 'Switzerland',
      countryCode: 'Switzerland',
      hasRegions: true,
      report: {
        impressions: 14,
        interactions: 0,
      },
    },
    {
      country: 'Taiwan',
      countryCode: 'Taiwan',
      hasRegions: true,
      report: {
        impressions: 9,
        interactions: 0,
      },
    },
    {
      country: 'Turkey',
      countryCode: 'Turkey',
      hasRegions: true,
      report: {
        impressions: 20,
        interactions: 0,
      },
    },
    {
      country: 'Ukraine',
      countryCode: 'Ukraine',
      report: {
        impressions: 1,
        interactions: 0,
      },
    },
    {
      country: 'United Kingdom',
      countryCode: 'United Kingdom',
      hasRegions: true,
      report: {
        impressions: 3312,
        interactions: 78,
      },
    },
    {
      country: 'United States',
      countryCode: 'United States',
      hasRegions: true,
      report: {
        impressions: 16447,
        interactions: 351,
      },
    },
  ],
  region: {
    Argentina: [
      {
        region: 'Buenos Aires',
        report: {
          impressions: 27,
          interactions: 0,
        },
      },
      {
        region: 'Cordoba',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Entre Rios',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Mendoza Province',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Neuquen',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'San Juan Province',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Santa Fe Province',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Australia: [
      {
        region: '-',
        report: {
          impressions: 27,
          interactions: 0,
        },
      },
      {
        region: 'Australian Capital Territory',
        report: {
          impressions: 15,
          interactions: 1,
        },
      },
      {
        region: 'New South Wales',
        report: {
          impressions: 249,
          interactions: 6,
        },
      },
      {
        region: 'Northern Territory',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Queensland',
        report: {
          impressions: 115,
          interactions: 1,
        },
      },
      {
        region: 'South Australia',
        report: {
          impressions: 57,
          interactions: 0,
        },
      },
      {
        region: 'Tasmania',
        report: {
          impressions: 13,
          interactions: 2,
        },
      },
      {
        region: 'Victoria',
        report: {
          impressions: 260,
          interactions: 6,
        },
      },
      {
        region: 'Western Australia',
        report: {
          impressions: 71,
          interactions: 3,
        },
      },
    ],
    Austria: [
      {
        region: 'Burgenland',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Styria',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Vienna',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
    ],
    Belgium: [
      {
        region: '-',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Brussels',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Flanders',
        report: {
          impressions: 14,
          interactions: 0,
        },
      },
      {
        region: 'Wallonia',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
    ],
    Brazil: [
      {
        region: '-',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Federal District',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'State of Alagoas',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'State of Bahia',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'State of Ceará',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'State of Espírito Santo',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'State of Maranhão',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'State of Mato Grosso do Sul',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'State of Minas Gerais',
        report: {
          impressions: 10,
          interactions: 0,
        },
      },
      {
        region: 'State of Pará',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'State of Paraná',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'State of Pernambuco',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'State of Piauí',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'State of Rio de Janeiro',
        report: {
          impressions: 16,
          interactions: 0,
        },
      },
      {
        region: 'State of Rio Grande do Sul',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'State of Santa Catarina',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'State of São Paulo',
        report: {
          impressions: 53,
          interactions: 0,
        },
      },
    ],
    Canada: [
      {
        region: '-',
        report: {
          impressions: 26,
          interactions: 0,
        },
      },
      {
        region: 'Alberta',
        report: {
          impressions: 372,
          interactions: 5,
        },
      },
      {
        region: 'British Columbia',
        report: {
          impressions: 494,
          interactions: 5,
        },
      },
      {
        region: 'Manitoba',
        report: {
          impressions: 150,
          interactions: 4,
        },
      },
      {
        region: 'New Brunswick',
        report: {
          impressions: 63,
          interactions: 2,
        },
      },
      {
        region: 'Newfoundland and Labrador',
        report: {
          impressions: 83,
          interactions: 3,
        },
      },
      {
        region: 'Northwest Territories',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
      {
        region: 'Nova Scotia',
        report: {
          impressions: 76,
          interactions: 0,
        },
      },
      {
        region: 'Nunavut',
        report: {
          impressions: 9,
          interactions: 0,
        },
      },
      {
        region: 'Ontario',
        report: {
          impressions: 1193,
          interactions: 31,
        },
      },
      {
        region: 'Prince Edward Island',
        report: {
          impressions: 13,
          interactions: 1,
        },
      },
      {
        region: 'Quebec',
        report: {
          impressions: 613,
          interactions: 14,
        },
      },
      {
        region: 'Saskatchewan',
        report: {
          impressions: 110,
          interactions: 2,
        },
      },
      {
        region: 'Yukon',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
    ],
    Chile: [
      {
        region: 'Maule',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Santiago Metropolitan Region',
        report: {
          impressions: 13,
          interactions: 0,
        },
      },
    ],
    China: [
      {
        region: 'Fujian',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Guangdong Province',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Shandong',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Shanghai',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
    ],
    Colombia: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Antioquia',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Atlantico',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Bogota',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
      {
        region: 'Bolivar',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Cundinamarca',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    'Costa Rica': [
      {
        region: 'Alajuela Province',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Heredia Province',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'San José Province',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
    ],
    Czechia: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Moravian-Silesian Region',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Pardubice Region',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Prague',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'South Moravian Region',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Vysocina Region',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Denmark: [
      {
        region: 'Capital Region of Denmark',
        report: {
          impressions: 13,
          interactions: 0,
        },
      },
      {
        region: 'Central Denmark Region',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Region of Southern Denmark',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Region Zealand',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    'Dominican Republic': [
      {
        region: 'Santiago Province',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Ecuador: [
      {
        region: 'Guayas',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
    ],
    Egypt: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Cairo Governorate',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Damietta Governorate',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'El Beheira Governorate',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Finland: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Uusimaa',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
    ],
    France: [
      {
        region: '-',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Auvergne-Rhône-Alpes',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Brittany',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Grand Est',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Hauts-de-France',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Île-de-France',
        report: {
          impressions: 21,
          interactions: 6,
        },
      },
      {
        region: 'Nouvelle-Aquitaine',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Occitanie',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Pays de la Loire',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Provence-Alpes-Côte d\'Azur',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
    ],
    Germany: [
      {
        region: '-',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Baden-Württemberg',
        report: {
          impressions: 16,
          interactions: 0,
        },
      },
      {
        region: 'Bavaria',
        report: {
          impressions: 11,
          interactions: 0,
        },
      },
      {
        region: 'Berlin',
        report: {
          impressions: 12,
          interactions: 0,
        },
      },
      {
        region: 'Brandenburg',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Bremen',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Hamburg',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'Hesse',
        report: {
          impressions: 16,
          interactions: 0,
        },
      },
      {
        region: 'Lower Saxony',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
      {
        region: 'North Rhine-Westphalia',
        report: {
          impressions: 24,
          interactions: 0,
        },
      },
      {
        region: 'Rhineland-Palatinate',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Saxony',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Saxony-Anhalt',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Schleswig-Holstein',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Thuringia',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
    ],
    Greece: [
      {
        region: '-',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Attica',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Central Macedonia',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'East Macedonia and Thrace',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Thessalia',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Western Greece',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Hungary: [
      {
        region: 'Békés County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Budapest',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Gyor-Moson-Sopron',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Pest County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Iceland: [
      {
        region: 'Capital Region',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
    ],
    India: [
      {
        region: '-',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'Andhra Pradesh',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Assam',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Chhattisgarh',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Delhi',
        report: {
          impressions: 10,
          interactions: 0,
        },
      },
      {
        region: 'Gujarat',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Haryana',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Himachal Pradesh',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Karnataka',
        report: {
          impressions: 15,
          interactions: 0,
        },
      },
      {
        region: 'Kerala',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
      {
        region: 'Madhya Pradesh',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Maharashtra',
        report: {
          impressions: 23,
          interactions: 0,
        },
      },
      {
        region: 'Punjab',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Rajasthan',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Tamil Nadu',
        report: {
          impressions: 12,
          interactions: 1,
        },
      },
      {
        region: 'Telangana',
        report: {
          impressions: 12,
          interactions: 0,
        },
      },
      {
        region: 'Uttar Pradesh',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'West Bengal',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
    ],
    Ireland: [
      {
        region: '-',
        report: {
          impressions: 18,
          interactions: 0,
        },
      },
      {
        region: 'County Carlow',
        report: {
          impressions: 18,
          interactions: 0,
        },
      },
      {
        region: 'County Cavan',
        report: {
          impressions: 11,
          interactions: 0,
        },
      },
      {
        region: 'County Clare',
        report: {
          impressions: 20,
          interactions: 0,
        },
      },
      {
        region: 'County Cork',
        report: {
          impressions: 117,
          interactions: 4,
        },
      },
      {
        region: 'County Donegal',
        report: {
          impressions: 34,
          interactions: 0,
        },
      },
      {
        region: 'County Dublin',
        report: {
          impressions: 403,
          interactions: 11,
        },
      },
      {
        region: 'County Galway',
        report: {
          impressions: 56,
          interactions: 5,
        },
      },
      {
        region: 'County Kerry',
        report: {
          impressions: 21,
          interactions: 2,
        },
      },
      {
        region: 'County Kildare',
        report: {
          impressions: 41,
          interactions: 1,
        },
      },
      {
        region: 'County Kilkenny',
        report: {
          impressions: 9,
          interactions: 1,
        },
      },
      {
        region: 'County Laois',
        report: {
          impressions: 15,
          interactions: 1,
        },
      },
      {
        region: 'County Leitrim',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'County Limerick',
        report: {
          impressions: 52,
          interactions: 1,
        },
      },
      {
        region: 'County Longford',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'County Louth',
        report: {
          impressions: 27,
          interactions: 3,
        },
      },
      {
        region: 'County Mayo',
        report: {
          impressions: 9,
          interactions: 1,
        },
      },
      {
        region: 'County Meath',
        report: {
          impressions: 16,
          interactions: 0,
        },
      },
      {
        region: 'County Monaghan',
        report: {
          impressions: 15,
          interactions: 0,
        },
      },
      {
        region: 'County Offaly',
        report: {
          impressions: 12,
          interactions: 0,
        },
      },
      {
        region: 'County Roscommon',
        report: {
          impressions: 9,
          interactions: 0,
        },
      },
      {
        region: 'County Sligo',
        report: {
          impressions: 10,
          interactions: 0,
        },
      },
      {
        region: 'County Tipperary',
        report: {
          impressions: 31,
          interactions: 0,
        },
      },
      {
        region: 'County Waterford',
        report: {
          impressions: 29,
          interactions: 1,
        },
      },
      {
        region: 'County Westmeath',
        report: {
          impressions: 24,
          interactions: 1,
        },
      },
      {
        region: 'County Wexford',
        report: {
          impressions: 39,
          interactions: 2,
        },
      },
      {
        region: 'County Wicklow',
        report: {
          impressions: 28,
          interactions: 2,
        },
      },
    ],
    Italy: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Abruzzo',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Apulia',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Campania',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Emilia-Romagna',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'Friuli-Venezia Giulia',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Lazio',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Liguria',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Lombardy',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
      {
        region: 'Marche',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Sardinia',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Sicily',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Trentino-South Tyrol',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Tuscany',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Umbria',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Veneto',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
    ],
    Jamaica: [
      {
        region: '-',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'St. Andrew Parish',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
    ],
    Japan: [
      {
        region: '-',
        report: {
          impressions: 22,
          interactions: 0,
        },
      },
      {
        region: 'Aichi',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Chiba',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Fukuoka',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Gifu',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Gunma',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Hyogo',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
      {
        region: 'Iwate',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Kanagawa',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'Kyoto',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Miyagi',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Nagano',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Okayama',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Okinawa',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Osaka',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Saga',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Saitama',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Shiga',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Tochigi',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Tokyo',
        report: {
          impressions: 14,
          interactions: 0,
        },
      },
      {
        region: 'Yamagata',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Yamaguchi',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Yamanashi',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Mexico: [
      {
        region: '-',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Aguascalientes',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Baja California',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Campeche',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Chiapas',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Coahuila',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Colima',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Durango',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Guanajuato',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Jalisco',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
      {
        region: 'Mexico City',
        report: {
          impressions: 26,
          interactions: 0,
        },
      },
      {
        region: 'Michoacán',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Morelos',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Nuevo Leon',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Oaxaca',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Puebla',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Querétaro',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'San Luis Potosi',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Sinaloa',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'State of Mexico',
        report: {
          impressions: 10,
          interactions: 0,
        },
      },
      {
        region: 'Tamaulipas',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Veracruz',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Yucatan',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
    ],
    Netherlands: [
      {
        region: '-',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Flevoland',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Friesland',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Gelderland',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Groningen',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Limburg',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'North Brabant',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
      {
        region: 'North Holland',
        report: {
          impressions: 16,
          interactions: 0,
        },
      },
      {
        region: 'Overijssel',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'South Holland',
        report: {
          impressions: 15,
          interactions: 0,
        },
      },
      {
        region: 'Utrecht',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
      {
        region: 'Zeeland',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
    ],
    'New Zealand': [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Auckland',
        report: {
          impressions: 123,
          interactions: 4,
        },
      },
      {
        region: 'Bay of Plenty',
        report: {
          impressions: 19,
          interactions: 1,
        },
      },
      {
        region: 'Canterbury',
        report: {
          impressions: 32,
          interactions: 1,
        },
      },
      {
        region: 'Gisborne',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
      {
        region: 'Hawke\'s Bay',
        report: {
          impressions: 9,
          interactions: 0,
        },
      },
      {
        region: 'Manawatu-Wanganui',
        report: {
          impressions: 9,
          interactions: 2,
        },
      },
      {
        region: 'Marlborough',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Nelson',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Northland',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
      {
        region: 'Otago',
        report: {
          impressions: 16,
          interactions: 0,
        },
      },
      {
        region: 'Southland',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
      {
        region: 'Taranaki',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
      {
        region: 'Waikato',
        report: {
          impressions: 24,
          interactions: 0,
        },
      },
      {
        region: 'Wellington',
        report: {
          impressions: 35,
          interactions: 2,
        },
      },
      {
        region: 'West Coast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Nicaragua: [
      {
        region: 'Managua',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Norway: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Agder',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Innlandet',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Møre og Romsdal',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Nordland',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Oslo',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Troms og Finnmark',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Trøndelag',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Vestland',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Viken',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
    ],
    Panama: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Panama',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
    ],
    Paraguay: [
      {
        region: 'Central Department',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Peru: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Arequipa',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Callao Region',
        report: {
          impressions: 18,
          interactions: 0,
        },
      },
      {
        region: 'La Libertad',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Lambayeque',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Philippines: [
      {
        region: '-',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
      {
        region: 'Calabarzon',
        report: {
          impressions: 13,
          interactions: 0,
        },
      },
      {
        region: 'Central Luzon',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'Central Visayas',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
      {
        region: 'Cordillera Administrative Region',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Ilocos Region',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Metro Manila',
        report: {
          impressions: 35,
          interactions: 0,
        },
      },
      {
        region: 'Soccsksargen',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Western Visayas',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
    ],
    Poland: [
      {
        region: '-',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Greater Poland Voivodeship',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Lesser Poland Voivodeship',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Łódź Voivodeship',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Lower Silesian Voivodeship',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Masovian Voivodeship',
        report: {
          impressions: 12,
          interactions: 0,
        },
      },
      {
        region: 'Podkarpackie Voivodeship',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Silesian Voivodeship',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
    ],
    Portugal: [
      {
        region: 'Coimbra District',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Faro District',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Guarda District',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Lisbon',
        report: {
          impressions: 9,
          interactions: 0,
        },
      },
      {
        region: 'Porto District',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Vila Real District',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    'Puerto Rico': [
      {
        region: '-',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
    ],
    'Republic of Korea': [
      {
        region: '-',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'Busan',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Chungcheongbuk-do',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Gwangju',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Gyeonggi-do',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Gyeongsangnam-do',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Seoul',
        report: {
          impressions: 7,
          interactions: 0,
        },
      },
      {
        region: 'Ulsan',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Romania: [
      {
        region: 'Bihor County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Brașov County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Bucharest',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Ialomița County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Neamț County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Russia: [
      {
        region: '-',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'Amur Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Astrakhan Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Belgorod Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Chelyabinsk Oblast',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Chuvashia Republic',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Krasnodar Krai',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Kursk Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Moscow',
        report: {
          impressions: 6,
          interactions: 0,
        },
      },
      {
        region: 'Moscow Oblast',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
      {
        region: 'Nizhny Novgorod Oblast',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Novosibirsk Oblast',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Oryol Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Penza Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Primorsky Krai',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Republic of Bashkortostan',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Republic of Karelia',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Saint Petersburg',
        report: {
          impressions: 10,
          interactions: 0,
        },
      },
      {
        region: 'Sakhalin Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Samara Oblast',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Smolensk Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Sverdlovsk Oblast',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Tomsk Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Tula Oblast',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Ulyanovsk Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    'South Africa': [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Gauteng',
        report: {
          impressions: 16,
          interactions: 0,
        },
      },
      {
        region: 'KwaZulu-Natal',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Western Cape',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
    ],
    Spain: [
      {
        region: '-',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Andalusia',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Asturias',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Basque Country',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Canary Islands',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Castile and León',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Castile-La Mancha',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Catalonia',
        report: {
          impressions: 14,
          interactions: 0,
        },
      },
      {
        region: 'Community of Madrid',
        report: {
          impressions: 15,
          interactions: 0,
        },
      },
      {
        region: 'Extremadura',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Galicia',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Region of Murcia',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Valencian Community',
        report: {
          impressions: 8,
          interactions: 0,
        },
      },
    ],
    Sweden: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Dalarna County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Jamtland County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Jonkoping County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Kalmar County',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Skåne County',
        report: {
          impressions: 5,
          interactions: 0,
        },
      },
      {
        region: 'Södermanland County',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Stockholm County',
        report: {
          impressions: 11,
          interactions: 0,
        },
      },
      {
        region: 'Uppsala County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Västerbotten County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Västmanland County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Västra Götaland County',
        report: {
          impressions: 4,
          interactions: 3,
        },
      },
    ],
    Switzerland: [
      {
        region: 'Canton of Bern',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Thurgau',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Ticino',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Zurich',
        report: {
          impressions: 10,
          interactions: 0,
        },
      },
    ],
    Taiwan: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Changhua County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Kaohsiung City',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Pingtung County',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Taipei City',
        report: {
          impressions: 4,
          interactions: 0,
        },
      },
    ],
    Turkey: [
      {
        region: 'Adana',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Ankara',
        report: {
          impressions: 2,
          interactions: 0,
        },
      },
      {
        region: 'Bartın',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Bursa',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'İstanbul',
        report: {
          impressions: 9,
          interactions: 0,
        },
      },
      {
        region: 'İzmir',
        report: {
          impressions: 3,
          interactions: 0,
        },
      },
      {
        region: 'Kırşehir',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Şırnak',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    Ukraine: [
      {
        region: 'Odessa Oblast',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
    'United Kingdom': [
      {
        region: '-',
        report: {
          impressions: 85,
          interactions: 0,
        },
      },
      {
        region: 'England',
        report: {
          impressions: 2276,
          interactions: 44,
        },
      },
      {
        region: 'Northern Ireland',
        report: {
          impressions: 48,
          interactions: 0,
        },
      },
      {
        region: 'Scotland',
        report: {
          impressions: 182,
          interactions: 10,
        },
      },
      {
        region: 'Wales',
        report: {
          impressions: 132,
          interactions: 8,
        },
      },
    ],
    'United States': [
      {
        region: '-',
        report: {
          impressions: 317,
          interactions: 1,
        },
      },
      {
        region: 'Alabama',
        report: {
          impressions: 143,
          interactions: 4,
        },
      },
      {
        region: 'Alaska',
        report: {
          impressions: 20,
          interactions: 0,
        },
      },
      {
        region: 'Arizona',
        report: {
          impressions: 250,
          interactions: 1,
        },
      },
      {
        region: 'Arkansas',
        report: {
          impressions: 76,
          interactions: 2,
        },
      },
      {
        region: 'California',
        report: {
          impressions: 4293,
          interactions: 114,
        },
      },
      {
        region: 'Colorado',
        report: {
          impressions: 303,
          interactions: 5,
        },
      },
      {
        region: 'Connecticut',
        report: {
          impressions: 170,
          interactions: 3,
        },
      },
      {
        region: 'Delaware',
        report: {
          impressions: 26,
          interactions: 1,
        },
      },
      {
        region: 'District of Columbia',
        report: {
          impressions: 69,
          interactions: 0,
        },
      },
      {
        region: 'Florida',
        report: {
          impressions: 727,
          interactions: 17,
        },
      },
      {
        region: 'Georgia',
        report: {
          impressions: 414,
          interactions: 17,
        },
      },
      {
        region: 'Hawaii',
        report: {
          impressions: 62,
          interactions: 1,
        },
      },
      {
        region: 'Idaho',
        report: {
          impressions: 40,
          interactions: 2,
        },
      },
      {
        region: 'Illinois',
        report: {
          impressions: 648,
          interactions: 14,
        },
      },
      {
        region: 'Indiana',
        report: {
          impressions: 201,
          interactions: 4,
        },
      },
      {
        region: 'Iowa',
        report: {
          impressions: 105,
          interactions: 4,
        },
      },
      {
        region: 'Kansas',
        report: {
          impressions: 81,
          interactions: 0,
        },
      },
      {
        region: 'Kentucky',
        report: {
          impressions: 149,
          interactions: 4,
        },
      },
      {
        region: 'Louisiana',
        report: {
          impressions: 174,
          interactions: 4,
        },
      },
      {
        region: 'Maine',
        report: {
          impressions: 44,
          interactions: 1,
        },
      },
      {
        region: 'Maryland',
        report: {
          impressions: 261,
          interactions: 6,
        },
      },
      {
        region: 'Massachusetts',
        report: {
          impressions: 394,
          interactions: 13,
        },
      },
      {
        region: 'Michigan',
        report: {
          impressions: 299,
          interactions: 8,
        },
      },
      {
        region: 'Minnesota',
        report: {
          impressions: 202,
          interactions: 6,
        },
      },
      {
        region: 'Mississippi',
        report: {
          impressions: 86,
          interactions: 1,
        },
      },
      {
        region: 'Missouri',
        report: {
          impressions: 166,
          interactions: 2,
        },
      },
      {
        region: 'Montana',
        report: {
          impressions: 29,
          interactions: 0,
        },
      },
      {
        region: 'Nebraska',
        report: {
          impressions: 93,
          interactions: 4,
        },
      },
      {
        region: 'Nevada',
        report: {
          impressions: 186,
          interactions: 1,
        },
      },
      {
        region: 'New Hampshire',
        report: {
          impressions: 46,
          interactions: 0,
        },
      },
      {
        region: 'New Jersey',
        report: {
          impressions: 418,
          interactions: 7,
        },
      },
      {
        region: 'New Mexico',
        report: {
          impressions: 84,
          interactions: 0,
        },
      },
      {
        region: 'New York',
        report: {
          impressions: 1093,
          interactions: 18,
        },
      },
      {
        region: 'North Carolina',
        report: {
          impressions: 377,
          interactions: 6,
        },
      },
      {
        region: 'North Dakota',
        report: {
          impressions: 20,
          interactions: 0,
        },
      },
      {
        region: 'Ohio',
        report: {
          impressions: 337,
          interactions: 6,
        },
      },
      {
        region: 'Oklahoma',
        report: {
          impressions: 124,
          interactions: 2,
        },
      },
      {
        region: 'Oregon',
        report: {
          impressions: 177,
          interactions: 0,
        },
      },
      {
        region: 'Pennsylvania',
        report: {
          impressions: 394,
          interactions: 5,
        },
      },
      {
        region: 'Rhode Island',
        report: {
          impressions: 40,
          interactions: 0,
        },
      },
      {
        region: 'South Carolina',
        report: {
          impressions: 165,
          interactions: 3,
        },
      },
      {
        region: 'South Dakota',
        report: {
          impressions: 26,
          interactions: 0,
        },
      },
      {
        region: 'Tennessee',
        report: {
          impressions: 237,
          interactions: 4,
        },
      },
      {
        region: 'Texas',
        report: {
          impressions: 1236,
          interactions: 27,
        },
      },
      {
        region: 'Utah',
        report: {
          impressions: 93,
          interactions: 4,
        },
      },
      {
        region: 'Vermont',
        report: {
          impressions: 28,
          interactions: 2,
        },
      },
      {
        region: 'Virginia',
        report: {
          impressions: 367,
          interactions: 1,
        },
      },
      {
        region: 'Washington',
        report: {
          impressions: 266,
          interactions: 3,
        },
      },
      {
        region: 'West Virginia',
        report: {
          impressions: 79,
          interactions: 1,
        },
      },
      {
        region: 'Wisconsin',
        report: {
          impressions: 204,
          interactions: 5,
        },
      },
      {
        region: 'Wyoming',
        report: {
          impressions: 20,
          interactions: 0,
        },
      },
    ],
    Israel: [
      {
        region: '-',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
      {
        region: 'Tel Aviv District',
        report: {
          impressions: 1,
          interactions: 0,
        },
      },
    ],
  },
  creative: [
    {
      report: {
        impressions: 2221,
        interactions: 4,
      },
      creative: {
        id: '1678606',
        properties: {
          objective: 'Click',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1678606/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 2207,
        interactions: 1,
      },
      creative: {
        id: '1678626',
        properties: {
          objective: 'Click',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1678626/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 1484,
        interactions: 31,
      },
      creative: {
        id: '1678642',
        properties: {
          objective: 'Feedback',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1678642/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 1761,
        interactions: 2,
      },
      creative: {
        id: '1678644',
        properties: {
          objective: 'Play',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1678644/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 99,
        interactions: 4,
      },
      creative: {
        id: '1678652',
        properties: {
          objective: 'Feedback',
          geo: 'Chicago IL',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1678652/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 112,
        interactions: 4,
      },
      creative: {
        id: '1678655',
        properties: {
          objective: 'Feedback',
          geo: 'Boston MA-Manchester NH',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1678655/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 1294,
        interactions: 6,
      },
      creative: {
        id: '1678659',
        properties: {
          objective: 'Play',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1678659/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 1535,
        interactions: 27,
      },
      creative: {
        id: '1678666',
        properties: {
          objective: 'Feedback',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1678666/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 129,
        interactions: 3,
      },
      creative: {
        id: '1679027',
        properties: {
          objective: 'Feedback',
          geo: 'San Francisco-Oakland-San Jose CA',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1679027/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 144,
        interactions: 0,
      },
      creative: {
        id: '1679028',
        properties: {
          objective: 'Feedback',
          geo: 'New York NY',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1679028/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 87,
        interactions: 3,
      },
      creative: {
        id: '1679029',
        properties: {
          objective: 'Feedback',
          geo: 'Miami-Ft. Lauderdale FL',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1679029/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 76,
        interactions: 0,
      },
      creative: {
        id: '1679030',
        properties: {
          objective: 'Feedback',
          geo: 'Las Vegas NV',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1679030/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 131,
        interactions: 3,
      },
      creative: {
        id: '1679031',
        properties: {
          objective: 'Feedback',
          geo: 'Los Angeles CA',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1679031/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 121,
        interactions: 3,
      },
      creative: {
        id: '1679032',
        properties: {
          objective: 'Feedback',
          geo: 'Denver CO',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1679032/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 112,
        interactions: 3,
      },
      creative: {
        id: '1679033',
        properties: {
          objective: 'Feedback',
          geo: 'Washington DC',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1679033/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 17,
        interactions: 3,
      },
      creative: {
        id: '1679034',
        properties: {
          objective: 'Feedback',
          geo: 'London, United Kingdom',
        },
        network: 'DISPLAY',
        display: {
          screenshotUrl: 'https://bandlab-zire.imgix.net/user=376393/campaign=413200/creative=1679034/creative.png?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
        },
        facebook: {
          color_palette: {},
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 90,
        interactions: 3,
      },
      creative: {
        id: '1678603',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-521984082-2.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.452685,
            color1: 'f7f6f4',
            color2: 'd8dbe4',
            color3: '98a8c7',
            color4: '926a62',
            color5: '70453a',
            color6: '2b384f',
            vibrant: 'e19f6f',
            vibrantDark: '114471',
            vibrantLight: '9fc6e4',
            muted: 'ab6a57',
            mutedDark: '2b384f',
            mutedLight: 'acb4cc',
          },
          primaryText: '⭐ JUST DROPPED ⭐',
          headline: 'New electronic single by Soni Shae',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'NO_BUTTON',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 1407,
        interactions: 50,
      },
      creative: {
        id: '1678611',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-472290969.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.30673,
            color1: '24d233',
            color2: '1fba2e',
            color3: '1c9e26',
            color4: '1a7723',
            color5: '0f5b16',
            color6: '09340c',
            vibrant: '24d233',
            vibrantDark: '13741c',
            vibrantLight: '65c06e',
            muted: '6e996d',
            mutedDark: '4a5e49',
            mutedLight: '92c58f',
          },
          primaryText: 'Check out this new single! Appreciate the support! 🙏',
          headline: 'New music by Soni Shae',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'NO_BUTTON',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 28,
        interactions: 1,
      },
      creative: {
        id: '1678615',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-483495210.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.265593,
            color1: 'ffb1b6',
            color2: 'f554cc',
            color3: 'd06fd7',
            color4: '2b3eca',
            color5: '0036a8',
            color6: '001b76',
            vibrant: '006de9',
            vibrantDark: '001568',
            vibrantLight: 'fb6dcf',
            mutedLight: 'a8bcc8',
          },
          primaryText: 'Up-and-coming electronic music',
          headline: 'Listen to Soni Shae',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'LEARN_MORE',
          caption: 'deezer.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 43,
        interactions: 0,
      },
      creative: {
        id: '1678619',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1141254399.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.353005,
            color1: 'fe696a',
            color2: 'fe4858',
            color3: 'f751ca',
            color4: '6a2de2',
            color5: '0142bb',
            color6: '93046e',
            vibrant: 'ef104d',
            vibrantDark: '8c022f',
            vibrantLight: 'fe4858',
            muted: '9c5967',
            mutedDark: '29121b',
          },
          primaryText: 'Don\'t miss out on the latest electronic music.',
          headline: 'Listen to the latest single by Soni Shae',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'NO_BUTTON',
          caption: 'music.apple.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 648,
        interactions: 12,
      },
      creative: {
        id: '1678623',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/81450b90-511f-4faa-85b8-c31f0de64fe9?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.291477,
            color1: 'fde4e1',
            color2: '1cddec',
            color3: '9577c4',
            color4: '697798',
            color5: '8a1971',
            color6: '67231d',
            vibrant: 'ee0551',
            vibrantDark: '8b020b',
            vibrantLight: '6be7f8',
            muted: 'a555a8',
            mutedDark: '532e30',
            mutedLight: 'cbbcd5',
          },
          primaryText: 'New electronic just for you.',
          headline: 'What are you waiting for?',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'LISTEN_NOW',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 664,
        interactions: 25,
      },
      creative: {
        id: '1678635',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/6f537b8c-e093-4d9a-98fe-b3987bd9e78b?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.341875,
            color1: 'fc93ab',
            color2: 'b2cae5',
            color3: 'a92b57',
            color4: '91284b',
            color5: '712845',
            color6: '4b2518',
            vibrant: 'cb3969',
            vibrantDark: '562529',
            vibrantLight: 'fc93ab',
            muted: 'a65c6b',
            mutedDark: '562529',
            mutedLight: '9cb3d3',
          },
          primaryText: 'Looking for your next favorite electronic song?',
          headline: 'Take a listen',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'LEARN_MORE',
          caption: 'music.apple.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 793,
        interactions: 40,
      },
      creative: {
        id: '1679035',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/mzGOl2TpMhXw7x0eWh02917ta872/48dc93bc-63db-4f65-a1cd-7c0449a3167f?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.208286,
            color1: 'edcbb4',
            color2: 'cc2879',
            color3: 'a93390',
            color4: '6f172f',
            color5: '563627',
            color6: '3c2b22',
            vibrant: 'da9c2d',
            vibrantDark: '734715',
            vibrantLight: 'e171d0',
            muted: '9d5961',
            mutedDark: '522c2e',
            mutedLight: 'ddb5b6',
          },
          primaryText: 'You\'ve never heard electronic music like this before!',
          headline: 'Listen to Soni Shae',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'LISTEN_NOW',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 50,
        interactions: 1,
      },
      creative: {
        id: '1679036',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1181195649.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.410569,
            color1: 'eff2f1',
            color2: '02add6',
            color3: 'b2c616',
            color4: '6d8e9b',
            color5: '726c53',
            color6: '543531',
            vibrant: '02add6',
            vibrantDark: '01906a',
            vibrantLight: '63d2ea',
            muted: 'aaa462',
            mutedDark: '344b55',
            mutedLight: 'd4d2ac',
          },
          primaryText: 'This electronic single is trending 🚨',
          headline: 'New music by Soni Shae',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'LEARN_MORE',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 884,
        interactions: 28,
      },
      creative: {
        id: '1679037',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/6f537b8c-e093-4d9a-98fe-b3987bd9e78b?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.341875,
            color1: 'fc93ab',
            color2: 'b2cae5',
            color3: 'a92b57',
            color4: '91284b',
            color5: '712845',
            color6: '4b2518',
            vibrant: 'cb3969',
            vibrantDark: '562529',
            vibrantLight: 'fc93ab',
            muted: 'a65c6b',
            mutedDark: '562529',
            mutedLight: '9cb3d3',
          },
          primaryText: 'Electronic Track of the Day',
          headline: 'Listen to the latest single by Soni Shae.',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'LISTEN_NOW',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 533,
        interactions: 13,
      },
      creative: {
        id: '1679038',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/mzGOl2TpMhXw7x0eWh02917ta872/48dc93bc-63db-4f65-a1cd-7c0449a3167f?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.208286,
            color1: 'edcbb4',
            color2: 'cc2879',
            color3: 'a93390',
            color4: '6f172f',
            color5: '563627',
            color6: '3c2b22',
            vibrant: 'da9c2d',
            vibrantDark: '734715',
            vibrantLight: 'e171d0',
            muted: '9d5961',
            mutedDark: '522c2e',
            mutedLight: 'ddb5b6',
          },
          primaryText: 'Need some new music?',
          headline: 'Listen to the latest single by Soni Shae',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'NO_BUTTON',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 4670,
        interactions: 141,
      },
      creative: {
        id: '1679039',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1211171995.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.32255,
            color1: 'fefcfe',
            color2: 'c2c3c4',
            color3: '8f7888',
            color4: '737475',
            color5: '5f6061',
            color6: '320e27',
            vibrant: '9c0a6b',
            vibrantDark: '6f0e4f',
            vibrantLight: 'e1a3cd',
            muted: 'b06698',
            mutedDark: '43263a',
            mutedLight: 'cf96bd',
          },
          primaryText: 'Looking for new electronic music?',
          headline: 'Take a listen',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'LISTEN_NOW',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 1542,
        interactions: 61,
      },
      creative: {
        id: '1679040',
        properties: {},
        network: 'FACEBOOK',
        display: {},
        facebook: {
          placement: 'NEWS_FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/81450b90-511f-4faa-85b8-c31f0de64fe9?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.291477,
            color1: 'fde4e1',
            color2: '1cddec',
            color3: '9577c4',
            color4: '697798',
            color5: '8a1971',
            color6: '67231d',
            vibrant: 'ee0551',
            vibrantDark: '8b020b',
            vibrantLight: '6be7f8',
            muted: 'a555a8',
            mutedDark: '532e30',
            mutedLight: 'cbbcd5',
          },
          primaryText: 'You haven\'t heard electronic music like this before.',
          headline: 'Listen and let us know what you think.',
          description: 'Missed Calls by Soni Shae',
          callToAction: 'LEARN_MORE',
          caption: 'spotify.com',
        },
        instagram: {
          color_palette: {},
        },
      },
    },
    {
      report: {
        impressions: 206,
        interactions: 12,
      },
      creative: {
        id: '1678616',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/81450b90-511f-4faa-85b8-c31f0de64fe9?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.291477,
            color1: 'fde4e1',
            color2: '1cddec',
            color3: '9577c4',
            color4: '697798',
            color5: '8a1971',
            color6: '67231d',
            vibrant: 'ee0551',
            vibrantDark: '8b020b',
            vibrantLight: '6be7f8',
            muted: 'a555a8',
            mutedDark: '532e30',
            mutedLight: 'cbbcd5',
          },
          primaryText: 'Check out this new single! Appreciate the support! 🙏',
          callToAction: 'LISTEN_NOW',
        },
      },
    },
    {
      report: {
        impressions: 839,
        interactions: 32,
      },
      creative: {
        id: '1678628',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/6f537b8c-e093-4d9a-98fe-b3987bd9e78b?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.341875,
            color1: 'fc93ab',
            color2: 'b2cae5',
            color3: 'a92b57',
            color4: '91284b',
            color5: '712845',
            color6: '4b2518',
            vibrant: 'cb3969',
            vibrantDark: '562529',
            vibrantLight: 'fc93ab',
            muted: 'a65c6b',
            mutedDark: '562529',
            mutedLight: '9cb3d3',
          },
          primaryText: 'Up-and-coming electronic music',
          callToAction: 'LEARN_MORE',
        },
      },
    },
    {
      report: {
        impressions: 25,
        interactions: 0,
      },
      creative: {
        id: '1679041',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-835416856.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.478505,
            color1: 'f5d420',
            color2: 'e6d6c7',
            color3: 'd6baa5',
            color4: 'c69c89',
            color5: '9c775d',
            color6: '0e455b',
            vibrant: '01d9f5',
            vibrantDark: '035d86',
            vibrantLight: 'f5db58',
            muted: 'aa8763',
            mutedDark: '5a4531',
            mutedLight: 'cfb6a6',
          },
          primaryText: '⭐ JUST DROPPED ⭐\nNew electronic music by Soni Shae',
          callToAction: 'LEARN_MORE',
        },
      },
    },
    {
      report: {
        impressions: 532,
        interactions: 23,
      },
      creative: {
        id: '1679042',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/mzGOl2TpMhXw7x0eWh02917ta872/48dc93bc-63db-4f65-a1cd-7c0449a3167f?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.208286,
            color1: 'edcbb4',
            color2: 'cc2879',
            color3: 'a93390',
            color4: '6f172f',
            color5: '563627',
            color6: '3c2b22',
            vibrant: 'da9c2d',
            vibrantDark: '734715',
            vibrantLight: 'e171d0',
            muted: '9d5961',
            mutedDark: '522c2e',
            mutedLight: 'ddb5b6',
          },
          primaryText: 'We think we found your new fav electronic artist 👀',
          callToAction: 'LISTEN_NOW',
        },
      },
    },
    {
      report: {
        impressions: 18,
        interactions: 0,
      },
      creative: {
        id: '1679043',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1165494644.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.687785,
            color1: '061b24',
            color2: '044c51',
            color3: '038d90',
            color4: '05bac4',
            color5: 'cbdcd5',
            color6: 'd4eceb',
            vibrant: '05bac4',
            vibrantDark: '017b83',
            vibrantLight: 'f2ca90',
            muted: '5daca9',
            mutedDark: '4c3931',
            mutedLight: 'afd2ce',
          },
          primaryText: 'Looking for your next favorite electronic song?',
          callToAction: 'LISTEN_NOW',
        },
      },
    },
    {
      report: {
        impressions: 1126,
        interactions: 46,
      },
      creative: {
        id: '1679044',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'FEED',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/6f537b8c-e093-4d9a-98fe-b3987bd9e78b?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.341875,
            color1: 'fc93ab',
            color2: 'b2cae5',
            color3: 'a92b57',
            color4: '91284b',
            color5: '712845',
            color6: '4b2518',
            vibrant: 'cb3969',
            vibrantDark: '562529',
            vibrantLight: 'fc93ab',
            muted: 'a65c6b',
            mutedDark: '562529',
            mutedLight: '9cb3d3',
          },
          primaryText: 'The Electronic music single you\'ve been waiting for.',
          callToAction: 'LEARN_MORE',
        },
      },
    },
    {
      report: {
        impressions: 68,
        interactions: 0,
      },
      creative: {
        id: '1678616',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'STORY',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/81450b90-511f-4faa-85b8-c31f0de64fe9?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.291477,
            color1: 'fde4e1',
            color2: '1cddec',
            color3: '9577c4',
            color4: '697798',
            color5: '8a1971',
            color6: '67231d',
            vibrant: 'ee0551',
            vibrantDark: '8b020b',
            vibrantLight: '6be7f8',
            muted: 'a555a8',
            mutedDark: '532e30',
            mutedLight: 'cbbcd5',
          },
          primaryText: 'Check out this new single! Appreciate the support! 🙏',
          callToAction: 'LISTEN_NOW',
        },
      },
    },
    {
      report: {
        impressions: 580,
        interactions: 14,
      },
      creative: {
        id: '1678628',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'STORY',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/6f537b8c-e093-4d9a-98fe-b3987bd9e78b?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.341875,
            color1: 'fc93ab',
            color2: 'b2cae5',
            color3: 'a92b57',
            color4: '91284b',
            color5: '712845',
            color6: '4b2518',
            vibrant: 'cb3969',
            vibrantDark: '562529',
            vibrantLight: 'fc93ab',
            muted: 'a65c6b',
            mutedDark: '562529',
            mutedLight: '9cb3d3',
          },
          primaryText: 'Up-and-coming electronic music',
          callToAction: 'LEARN_MORE',
        },
      },
    },
    {
      report: {
        impressions: 4,
        interactions: 0,
      },
      creative: {
        id: '1679041',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'STORY',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-835416856.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.478505,
            color1: 'f5d420',
            color2: 'e6d6c7',
            color3: 'd6baa5',
            color4: 'c69c89',
            color5: '9c775d',
            color6: '0e455b',
            vibrant: '01d9f5',
            vibrantDark: '035d86',
            vibrantLight: 'f5db58',
            muted: 'aa8763',
            mutedDark: '5a4531',
            mutedLight: 'cfb6a6',
          },
          primaryText: '⭐ JUST DROPPED ⭐\nNew electronic music by Soni Shae',
          callToAction: 'LEARN_MORE',
        },
      },
    },
    {
      report: {
        impressions: 540,
        interactions: 12,
      },
      creative: {
        id: '1679042',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'STORY',
          imageUrl: 'https://bandlab-zire.imgix.net/user/mzGOl2TpMhXw7x0eWh02917ta872/48dc93bc-63db-4f65-a1cd-7c0449a3167f?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.208286,
            color1: 'edcbb4',
            color2: 'cc2879',
            color3: 'a93390',
            color4: '6f172f',
            color5: '563627',
            color6: '3c2b22',
            vibrant: 'da9c2d',
            vibrantDark: '734715',
            vibrantLight: 'e171d0',
            muted: '9d5961',
            mutedDark: '522c2e',
            mutedLight: 'ddb5b6',
          },
          primaryText: 'We think we found your new fav electronic artist 👀',
          callToAction: 'LISTEN_NOW',
        },
      },
    },
    {
      report: {
        impressions: 29,
        interactions: 0,
      },
      creative: {
        id: '1679043',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'STORY',
          imageUrl: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1165494644.jpg?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.687785,
            color1: '061b24',
            color2: '044c51',
            color3: '038d90',
            color4: '05bac4',
            color5: 'cbdcd5',
            color6: 'd4eceb',
            vibrant: '05bac4',
            vibrantDark: '017b83',
            vibrantLight: 'f2ca90',
            muted: '5daca9',
            mutedDark: '4c3931',
            mutedLight: 'afd2ce',
          },
          primaryText: 'Looking for your next favorite electronic song?',
          callToAction: 'LISTEN_NOW',
        },
      },
    },
    {
      report: {
        impressions: 597,
        interactions: 16,
      },
      creative: {
        id: '1679044',
        properties: {},
        network: 'INSTAGRAM',
        display: {},
        facebook: {
          color_palette: {},
        },
        instagram: {
          placement: 'STORY',
          imageUrl: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/6f537b8c-e093-4d9a-98fe-b3987bd9e78b?ar=1&auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
          color_palette: {
            averageLuminance: 0.341875,
            color1: 'fc93ab',
            color2: 'b2cae5',
            color3: 'a92b57',
            color4: '91284b',
            color5: '712845',
            color6: '4b2518',
            vibrant: 'cb3969',
            vibrantDark: '562529',
            vibrantLight: 'fc93ab',
            muted: 'a65c6b',
            mutedDark: '562529',
            mutedLight: '9cb3d3',
          },
          primaryText: 'The Electronic music single you\'ve been waiting for.',
          callToAction: 'LEARN_MORE',
        },
      },
    },
  ],
  image: [
    {
      id: '116',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-619382582-2.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Wallpaper',
      report: {
        impressions: 129,
        interactions: 3,
      },
    },
    {
      id: '158',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-835416856.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Fan',
      report: {
        impressions: 29,
        interactions: 0,
      },
    },
    {
      id: '199',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-905788544.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Wallpaper',
      report: {
        impressions: 131,
        interactions: 3,
      },
    },
    {
      id: '218',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-918069670.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Wallpaper',
      report: {
        impressions: 121,
        interactions: 3,
      },
    },
    {
      id: '234',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-931686668.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Genre',
      report: {
        impressions: 1294,
        interactions: 6,
      },
    },
    {
      id: '252',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-949383448.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Fan',
      report: {
        impressions: 144,
        interactions: 0,
      },
    },
    {
      id: '499',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-533843930.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Wallpaper',
      report: {
        impressions: 112,
        interactions: 4,
      },
    },
    {
      id: '605',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1141254399.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Fan',
      report: {
        impressions: 43,
        interactions: 0,
      },
    },
    {
      id: '638',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1165494644.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Fan',
      report: {
        impressions: 47,
        interactions: 0,
      },
    },
    {
      id: '647',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1181195649.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Fan',
      report: {
        impressions: 50,
        interactions: 1,
      },
    },
    {
      id: '67',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-157673105.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Fan',
      report: {
        impressions: 2501,
        interactions: 9,
      },
    },
    {
      id: '678',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1211171995.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Genre',
      report: {
        impressions: 4964,
        interactions: 149,
      },
    },
    {
      id: '693',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-483495210.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Fan',
      report: {
        impressions: 28,
        interactions: 1,
      },
    },
    {
      id: '76',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-472290969.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Genre',
      report: {
        impressions: 1407,
        interactions: 50,
      },
    },
    {
      id: '9',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-1017781486.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Wallpaper',
      report: {
        impressions: 1829,
        interactions: 35,
      },
    },
    {
      id: '97',
      type: 'member_image',
      url: 'https://bandlab-zire.imgix.net/member/1/GettyImages-521984082-2.jpg?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'Fan',
      report: {
        impressions: 90,
        interactions: 3,
      },
    },
    {
      id: '292850',
      type: 'user_image',
      url: 'https://bandlab-zire.imgix.net/user/mzGOl2TpMhXw7x0eWh02917ta872/48dc93bc-63db-4f65-a1cd-7c0449a3167f?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'User',
      report: {
        impressions: 3788,
        interactions: 118,
      },
    },
    {
      id: '352438',
      type: 'user_image',
      url: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/81450b90-511f-4faa-85b8-c31f0de64fe9?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'User',
      report: {
        impressions: 5073,
        interactions: 100,
      },
    },
    {
      id: '352439',
      type: 'user_image',
      url: 'https://bandlab-zire.imgix.net/user/qyP0fYNPfSUJ6B8G2MCYBwnmKNF2/6f537b8c-e093-4d9a-98fe-b3987bd9e78b?auto=format&crop=faces&crop=edges&dpr=2.0&fit=crop&fm=jpg&q=90',
      theme: 'User',
      report: {
        impressions: 5668,
        interactions: 142,
      },
    },
  ],
  copy: [
    {
      header: 'Check out this new single! Appreciate the support! 🙏',
      report: {
        impressions: 274,
        interactions: 12,
      },
    },
    {
      header: 'Check out this new single! Appreciate the support! 🙏',
      subheader: 'New music by Soni Shae',
      report: {
        impressions: 1407,
        interactions: 50,
      },
    },
    {
      header: 'Does LA know electronic music?',
      report: {
        impressions: 131,
        interactions: 3,
      },
    },
    {
      header: 'Don\'t miss out on the latest electronic music.',
      subheader: 'Listen to the latest single by Soni Shae',
      report: {
        impressions: 43,
        interactions: 0,
      },
    },
    {
      header: 'Electronic Track of the Day',
      subheader: 'Listen to the latest single by Soni Shae.',
      report: {
        impressions: 884,
        interactions: 28,
      },
    },
    {
      header: '👋 Hey Boston! This Electronic music single is for you.',
      report: {
        impressions: 112,
        interactions: 4,
      },
    },
    {
      header: 'Hey DC! You haven\'t heard electronic music like this before.',
      report: {
        impressions: 112,
        interactions: 3,
      },
    },
    {
      header: 'Hey Miami!',
      subheader: 'What do you think of this song?',
      report: {
        impressions: 87,
        interactions: 3,
      },
    },
    {
      header: 'Hey San Francisco!',
      subheader: 'Rate this song',
      report: {
        impressions: 129,
        interactions: 3,
      },
    },
    {
      header: 'Hey Vegas!',
      subheader: 'What do you think of this electronic song by Soni Shae?',
      report: {
        impressions: 76,
        interactions: 0,
      },
    },
    {
      header: '⭐ JUST DROPPED ⭐',
      subheader: 'New electronic single by Soni Shae',
      report: {
        impressions: 90,
        interactions: 3,
      },
    },
    {
      header: '⭐ JUST DROPPED ⭐\nNew electronic music by Soni Shae',
      report: {
        impressions: 29,
        interactions: 0,
      },
    },
    {
      header: 'Listen to this before anyone else does.',
      report: {
        impressions: 1294,
        interactions: 6,
      },
    },
    {
      header: 'London',
      subheader: 'Be able to say you discovered Soni Shae first.',
      report: {
        impressions: 17,
        interactions: 3,
      },
    },
    {
      header: 'Looking for new electronic music?',
      subheader: 'Take a listen',
      report: {
        impressions: 5112,
        interactions: 154,
      },
    },
    {
      header: 'Looking for your next favorite electronic song?',
      report: {
        impressions: 47,
        interactions: 0,
      },
    },
    {
      header: 'Looking for your next favorite electronic song?',
      subheader: 'Take a listen',
      report: {
        impressions: 664,
        interactions: 25,
      },
    },
    {
      header: 'Need some new music?',
      subheader: 'Listen to the latest single by Soni Shae',
      report: {
        impressions: 533,
        interactions: 13,
      },
    },
    {
      header: 'New electronic just for you.',
      subheader: 'What are you waiting for?',
      report: {
        impressions: 648,
        interactions: 12,
      },
    },
    {
      header: 'Soni Shae',
      subheader: 'Listen to',
      report: {
        impressions: 2203,
        interactions: 14,
      },
    },
    {
      header: 'Soni Shae might be your new fave electronic artist.',
      subheader: 'Denver, don\'t miss out!',
      report: {
        impressions: 121,
        interactions: 3,
      },
    },
    {
      header: 'The Electronic music single you\'ve been waiting for.',
      report: {
        impressions: 1134,
        interactions: 45,
      },
    },
    {
      header: 'They say Chicago knows Electronic music.',
      subheader: 'Take a listen.',
      report: {
        impressions: 99,
        interactions: 4,
      },
    },
    {
      header: 'This electronic single is trending 🚨',
      subheader: 'New music by Soni Shae',
      report: {
        impressions: 50,
        interactions: 1,
      },
    },
    {
      header: 'Up-and-coming electronic music',
      report: {
        impressions: 830,
        interactions: 30,
      },
    },
    {
      header: 'Up-and-coming electronic music',
      subheader: 'Listen to Soni Shae',
      report: {
        impressions: 28,
        interactions: 1,
      },
    },
    {
      header: 'We need your feedback!',
      report: {
        impressions: 1535,
        interactions: 27,
      },
    },
    {
      header: 'We think we found your new fav electronic artist 👀',
      report: {
        impressions: 484,
        interactions: 19,
      },
    },
    {
      header: 'You haven\'t heard an Electronic artist like Soni Shae before.',
      subheader: 'Hey NYC!',
      report: {
        impressions: 144,
        interactions: 0,
      },
    },
    {
      header: 'You haven\'t heard electronic music like this before.',
      subheader: 'Listen and let us know what you think.',
      report: {
        impressions: 1542,
        interactions: 61,
      },
    },
    {
      header: 'You\'ll be playing the 💩 out of this one.',
      subheader: 'New single by Soni Shae',
      report: {
        impressions: 2648,
        interactions: 13,
      },
    },
    {
      header: 'You\'ll be playing this one on repeat.',
      report: {
        impressions: 1484,
        interactions: 31,
      },
    },
    {
      header: 'You need to hear this.',
      subheader: 'Listen to the latest single by Soni Shae.',
      report: {
        impressions: 2662,
        interactions: 16,
      },
    },
    {
      header: 'You\'ve never heard electronic music like this before!',
      subheader: 'Listen to Soni Shae',
      report: {
        impressions: 793,
        interactions: 40,
      },
    },
  ],
  demo: [
    {
      gender: 'female',
      ageRange: '18-24',
      report: {
        impressions: 274,
        interactions: 9,
      },
    },
    {
      gender: 'male',
      ageRange: '18-24',
      report: {
        impressions: 1078,
        interactions: 30,
      },
    },
    {
      gender: 'unknown',
      ageRange: '18-24',
      report: {
        impressions: 27,
        interactions: 0,
      },
    },
    {
      gender: 'female',
      ageRange: '25-34',
      report: {
        impressions: 233,
        interactions: 5,
      },
    },
    {
      gender: 'male',
      ageRange: '25-34',
      report: {
        impressions: 1377,
        interactions: 43,
      },
    },
    {
      gender: 'unknown',
      ageRange: '25-34',
      report: {
        impressions: 31,
        interactions: 0,
      },
    },
    {
      gender: 'female',
      ageRange: '35-44',
      report: {
        impressions: 412,
        interactions: 14,
      },
    },
    {
      gender: 'male',
      ageRange: '35-44',
      report: {
        impressions: 2362,
        interactions: 73,
      },
    },
    {
      gender: 'unknown',
      ageRange: '35-44',
      report: {
        impressions: 24,
        interactions: 0,
      },
    },
    {
      gender: 'female',
      ageRange: '45-54',
      report: {
        impressions: 1518,
        interactions: 54,
      },
    },
    {
      gender: 'male',
      ageRange: '45-54',
      report: {
        impressions: 6719,
        interactions: 251,
      },
    },
    {
      gender: 'unknown',
      ageRange: '45-54',
      report: {
        impressions: 95,
        interactions: 2,
      },
    },
  ],
};

const LoadedRegions = [
  'United States',
  'Romania',
  'United Kingdom',
  'Canada',
  'Portugal',
  'Spain',
  'Italy',
  'Greece',
  'Hungary',
  'Australia',
  'Poland',
  'Germany',
  'Czechia',
  'France',
  'Brazil',
  'India',
  'Japan',
  'Netherlands',
  'Belgium',
  'Mexico',
  'Russia',
  'Philippines',
  'Argentina',
  'Sweden',
  'Ireland',
  'Peru',
  'Austria',
  'Colombia',
  'South Africa',
  'Chile',
  'Norway',
  'New Zealand',
  'Switzerland',
  'Turkey',
  'Denmark',
  'Egypt',
  'Republic of Korea',
  'Finland',
  'Taiwan',
  'Uruguay',
  'Costa Rica',
  'China',
  'Iceland',
  'Ecuador',
  'Jamaica',
  'Nicaragua',
  'Ukraine',
  'Hong Kong',
  'Panama',
  'Puerto Rico',
  'Venezuela',
  'Guatemala',
  'Dominican Republic',
  'Paraguay',
  'Israel',
];

export {
  SampleCampaign,
  SampleReports,
  LoadedRegions,
};
