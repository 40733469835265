

















































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Route } from 'vue-router';
import store from '@/shared/store';
import { isEnded } from '@/shared/lib/campaign_status';
import {
  Campaign,
} from '@/shared/store/campaign';
import { CampaignReport } from '@/shared/store/campaignReports';
import LeafLayout from '@/shared/components/site/LeafLayout.vue';
import PageBody from '@/shared/components/campaign/results/PageBody.vue';
import ReleaseBrief from '@/shared/components/campaign/results/ReleaseBrief.vue';
import ReleaseDetails from '@/shared/components/campaign/results/ReleaseDetails.vue';
import { SampleCampaign, LoadedRegions, SampleReports } from '@/shared/lib/sampleReport';

@Component({
  components: {
    ReleaseBrief,
    ReleaseDetails,
    LeafLayout,
    PageBody,
  },
})
export default class SampleReport extends Vue {
  beforeRouteEnter(to: Route, from: Route, next: any) {
    Promise.all([
      store.dispatch('campaign/loadSampleReport', SampleCampaign),
      store.dispatch('campaignReports/loadSampleReport', {
        sampleReports: SampleReports,
        loadedRegions: LoadedRegions,
      }),
    ])
      .then(() => next())
      .catch(() => next({ name: 'campaigns' }));
  }

  @Getter('layout/isMobile') isMobile: boolean;

  @Getter('campaign/campaignLoaded') campaignLoaded: boolean;

  @Getter('campaign/campaignDetails') campaign: Campaign;

  @Getter('profile/userId') userId: string;

  get campaignReport(): CampaignReport | undefined {
    return this.$store.getters['campaignReports/campaignReport'](this.campaign.id);
  }

  onCancelConfirmationClosed: Function[] = [];

  fadeHeader: boolean = false;

  get isEnded() {
    return isEnded(this.campaign.status!);
  }

  get impressions() {
    const report = this.campaignReport;
    if (report) {
      return report.impressions;
    }
    return 0;
  }

  itemClicked(clickedItem?: string) {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        clickedItem,
        referringDiscountCode: this.$route.query.coupon,
      },
      action: 'Zire.SampleReportItemClicked',
    });
  }

  mounted() {}

  get isTabletOrAbove() {
    return !['xs', 'sm'].includes(this.$mq);
  }
}
